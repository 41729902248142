/* my css */
.pageTitle {
  align-items: center;
  display: flex;
  justify-content: center;
}

.toolBar {
  align-items: center;
  display: flex;
  justify-content: center;
}

.choose-file-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  cursor: pointer;
  border-radius: 4px;
}

.choose-file-button:hover {
  background-color: #1976d2;
}

.file-upload-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #2196f3;
  color: white;
  cursor: pointer;
  border-radius: 4px;
  border: none;
}

.file-upload-button:hover {
  background-color: #1976d2;
}

.chooseFileBtn {
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding-top: 10%;
}

.uploadBtn {
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding-top: 10%;
}

/* Dark mode styles */
body {
  background-color: #222;
  color: #fff;
}

.App-header {
  background-color: #222;
}

.choose-file-button,
.file-upload-button {
  color: #fff;
}

.choose-file-button:hover,
.file-upload-button:hover {
  background-color: #222;
  border: 2px solid #1976d2;
  border-radius: 5px;
  color: #1976d2;
}

.fileTitle {
  align-items: center;
  display: flex;
  justify-content: center;
  vertical-align: middle;
  padding-top: 10%;
}

.chosen-file-title {
  display: inline-block;
  padding: 10px 20px;
  background-color: #222;
  border: 2px solid #1976d2;
  color: #1976d2;
  cursor: pointer;
  border-radius: 4px;
}
