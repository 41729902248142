/* my css */
.pageTitle {
  align-items: center;
  display: flex;
  justify-content: center;
}

.toolBar {
  align-items: center;
  display: flex;
  justify-content: center;
}

.toggle-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: transparent;
  color: #2196f3;
  cursor: pointer;
  border-radius: 4px;
  border: none;
  transition: background-color 0.3s, color 0.3s;
}

.toggle-button:hover {
  background-color: #2196f3;
  color: white;
}

.toggle-button.enabled {
  background-color: #2196f3;
  border: none;
}

.toggle-button.enabled:hover {
  background-color: #1976d2;
}

.toggle-button.disabled {
  background-color: transparent;
  color: #2196f3;
}

.toggle-button.disabled:hover {
  background-color: #1976d2;
  color: white;
}

/* Dark mode styles */
body {
  background-color: #222;
  color: #fff;
}

.App-header {
  background-color: #222;
}

.toggle-button {
  color: #2196f3;
}

.toggle-button.enabled,
.toggle-button.enabled:hover {
  background-color: #1976d2;
  color: #fff;
}

.toggle-button:hover {
  background-color: #1976d2;
  color: #fff;
}
