.customBody {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15%;
}

.left {
  flex: 1;
  padding: 20px;
  text-align: center;
}

.right {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
}

.descriptionDiv {
  text-align: justify;
  display:inline-block ;
  justify-content: center;
  align-items: center;
}

.start-btn {
  padding: 15px 40px;
  text-decoration: none;
  background-color: #1976d2;
  border: 2px solid #1976d2;
  border-radius: 5px;
  color: #fff;
}

.start-btn:hover {
  padding: 15px 40px;
  text-decoration: none;
  background-color: #222;
  border: 2px solid #1976d2;
  border-radius: 5px;
  color: #1976d2;
}

/* Dark mode styles */
body {
  background-color: #222;
  color: #fff;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}
