/* Dark mode styles */
body {
  background-color: #222;
  color: #fff;
}

.App-header {
  background-color: #222;
}

/* 404 */

.NotFoundPageBody {
  background-color: #222;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  margin: 0;
  font-family: Arial, sans-serif;
}

.error-message {
  font-size: 6rem;
  text-align: center;
}
